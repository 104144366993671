import { api } from '@/utils';
import { plainToInstance } from 'class-transformer';
import { State } from './state';
import { User } from '@/models/user';
import { ActionContext, ActionTree } from 'vuex';

const getUser = (context: ActionContext<State, State>): void => {
  api
    .get('auth/user')
    .then((response) => {
      const user = plainToInstance(User, response.data);
      context.dispatch('setUser', user);
    })
    .catch((error) => {
      if (error.response.status === 401) {
        context.dispatch('logout');
      } else {
        console.error('Erro no logout', error);
      }
    });
};

const getRecomendationsFromAPI = (context: ActionContext<State, State>, payload: any): void => {
  context.commit('setDataRecomendations', payload);
};

const getBalanceFromRecomendations = (context: ActionContext<State, State>, payload: any): void => {
  context.commit('setBalanceFromRecomendations', payload);
};

const getDataToResults = (context: ActionContext<State, State>, payload: any): void => {
  context.commit('setDataToResults', payload);
};

const logout = (context: ActionContext<State, State>): void => {
  localStorage.removeItem('token');
  context.commit('setUser', null);
};

const setUser = (context: ActionContext<State, State>, payload: User | null): void => {
  context.commit('setUser', payload);
};

const actions: ActionTree<State, State> = {
  getUser,
  setUser,
  getRecomendationsFromAPI,
  getBalanceFromRecomendations,
  getDataToResults,
  logout,
};

export { actions };
