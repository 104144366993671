import DefaultLayout from '@/layouts/Default.vue';

const defaultRoutes = [
  {
    path: '/',
    name: 'Default',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: (): Promise<typeof import('*.vue')> => import('@/views/report/Index.vue'),
      },
      {
        path: '/teste',
        name: 'teste',
        component: (): Promise<typeof import('*.vue')> => import('@/views/report/Create.vue'),
      },
      {
        path: '/teste/:id',
        name: 'testeView',
        component: (): Promise<typeof import('*.vue')> => import('@/views/report/View.vue'),
      },
      {
        path: '/teste/:id/recomendacoes',
        name: 'testeRecomendations',
        component: (): Promise<typeof import('*.vue')> =>
          import('@/views/report/Recomendations.vue'),
      },
      {
        path: '/teste/:id/editar',
        name: 'testeEdit',
        component: (): Promise<typeof import('*.vue')> => import('@/views/report/Edit.vue'),
      },
      {
        path: '/teste/:id/resultados',
        name: 'testeResultados',
        component: (): Promise<typeof import('*.vue')> => import('@/views/report/Resultados.vue'),
      },
/*       {
        path: '/teste/:id/resultados/pdf',
        name: 'testeResultadosPDF',
        component: (): Promise<typeof import('*.vue')> => import('@/views/report/ResultadosPDF.vue'),
      }, */
    ],
  },
  {
    path: '/teste/:id/resultados/pdf',
    name: 'testeResultadosPDF',
    component: (): Promise<typeof import('*.vue')> => import('@/views/report/ResultadosPDF.vue'),
  },
];

export { defaultRoutes };
