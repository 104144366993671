import { User } from '@/models/user';

interface State {
  user: User | null;
  dataRecomendations: any;
  BalanceFromRecomendations: any;
  dataResults: any;
  substancesApplied: any;
}

const state: State = {
  user: null,
  dataRecomendations: null,
  BalanceFromRecomendations: {
    microBalance: ['0', '0', '0', '0', '0', '0'],
    macroBalance: ['0', '0', '0', '0', '0', '0'],
  },
  dataResults: null,
  substancesApplied: null,
};

export { state, State };
