import { MutationTree } from 'vuex';
import { State } from './state';
import { User } from '@/models/user';

const setUser = (state: State, payload: User | null): void => {
  state.user = payload;
};

const setDataRecomendations = (state: State, payload: any): void => {
  state.dataRecomendations = payload;
};

const setBalanceFromRecomendations = (state: State, payload: any): void => {
  state.BalanceFromRecomendations = payload;
};

const setDataToResults = (state: State, payload: any): void => {
  state.dataResults = payload;
};

const setSubstancesApplied = (state: State, payload: any): void => {
  state.substancesApplied = payload;
};



const mutations: MutationTree<State> = {
  setUser,
  setDataRecomendations,
  setBalanceFromRecomendations,
  setDataToResults,
  setSubstancesApplied
};

export { mutations };
