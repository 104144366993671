import { Model } from './model';
import { Expose, Transform } from 'class-transformer';
import moment, { Moment } from 'moment';

type UserType = 'admin' | 'supervisor' | 'user' | 'developer';

enum UserTypeString {
  admin = 'Administrador',
  supervisor = 'Supervisor',
  user = 'Usuário',
  developer = 'Desenvolvedor',
}

class User extends Model {
  public static TYPE_ADMIN = 'admin';
  public static TYPE_SUPERVISOR = 'supervisor';
  public static TYPE_USER = 'user';
  public static TYPE_DEVELOPER = 'developer';

  @Expose({ name: 'email_verified_at' })
  @Transform(({ value }) => (value ? moment(value) : null), { toClassOnly: true })
  public emailVerifiedAt: Moment;

  @Expose()
  public name: string;

  @Expose()
  public region: number | null;

  @Expose()
  public regional: number | null;

  @Expose()
  public email: string;

  @Expose()
  public document: string;

  @Expose()
  public type: UserType;

  @Expose()
  public phone: string;

  @Expose({ name: 'supervisor_id' })
  public supervisorId: number | null;

  constructor(
    id: number | null,
    createdAt: Moment,
    updatedAt: Moment,
    deletedAt: Moment,
    emailVerifiedAt: Moment,
    name: string,
    region: number,
    regional: number,
    email: string,
    document: string,
    type: UserType,
    phone: string,
    supervisorId: number | null
  ) {
    super(id, createdAt, updatedAt, deletedAt);

    this.emailVerifiedAt = emailVerifiedAt;
    this.name = name;
    this.region = region;
    this.regional = regional;
    this.email = email;
    this.document = document;
    this.type = type;
    this.phone = phone;
    this.supervisorId = supervisorId;
  }

  get isAdmin(): boolean {
    return this.type === 'admin';
  }

  get isDeveloper(): boolean {
    return this.type === 'developer';
  }
}

export { User, UserType, UserTypeString };
