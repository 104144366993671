<template>
  <div class="auth-layout-container">
    <main>
      <div class="row justify-center">
        <div class="col-xs-11 col-sm-8 col-md-6 col-lg-3">
          <q-card class="main-content">
            <q-card-section>
              <router-view />
            </q-card-section>
          </q-card>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';
.auth-layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  width: 100vw;
  height: 100vh;

  background-color: $primary;

  header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 10rem;
    padding: 2rem;
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;

    .row {
      width: 100%;

      .main-content {
        width: 100%;
      }
    }
  }
}
</style>
