import AuthLayout from '@/layouts/Auth.vue';
import { RouteRecordRaw } from 'vue-router';

const authRoutes: RouteRecordRaw[] = [
  {
    path: '/autenticacao',
    name: 'Autenticacao',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login.vue'),
      },
    ],
  },
];

export { authRoutes };
