import { api } from '@/utils';
import router from '@/router';
import store from '@/store';

const useLogout = (): (() => void) => {
  const logout = (): void => {
    api.get('auth/logout').then(() => {
      store.dispatch('logout');
      router.push('/autenticacao/login');
    });
  };

  return logout;
};

export { useLogout };
