import { adminRoutes } from './admin';
import { authRoutes } from './auth';
import { defaultRoutes } from './default';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [...defaultRoutes, ...adminRoutes, ...authRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
