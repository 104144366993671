import { GetterTree } from 'vuex';
import { State } from './state';
import { User } from '@/models/user';

type StoreGetter<T> = (state: State) => T;

const user: StoreGetter<User | null> = (state: State) => state.user;

const getters: GetterTree<State, State> = { user };

export { getters };
