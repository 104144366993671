import AdminLayout from '@/layouts/Admin.vue';
import { RouteRecordRaw } from 'vue-router';

const adminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin',
    redirect: '/admin/users',
    component: AdminLayout,
    children: [
      {
        path: 'users',
        component: () => import('@/views/admin/users/Index.vue'),
      },
      {
        path: 'formulas',
        component: () => import('@/views/admin/formulas/Index.vue'),
      },
      {
        path: 'analysis-items',
        component: () => import('@/views/admin/analysis-items/Index.vue'),
      },
      {
        path: 'reports',
        component: () => import('@/views/admin/reports/Index.vue'),
      },
    ],
  },
];

export { adminRoutes };
